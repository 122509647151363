import React, { useState, useRef } from "react"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Moment from "react-moment"
import Icon from "@mdi/react"
import {
  mdiPlayCircle,
  mdiPauseCircle,
  mdiChevronLeft,
  mdiChevronRight,
  mdiAlert,
  mdiClose,
  mdiOpenInNew,
} from "@mdi/js"
import AudioPlayer from "react-h5-audio-player"
import ReactMarkdown from "react-markdown"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Monetizer from "../components/monetizer"

import "../styles/audio-album-alt.css"
import "react-h5-audio-player/lib/styles.css"
import "../styles/image-modal.css"

const AudioAlbumAlt = ({ data, pageContext }) => {
  const nonNullField = field =>
    field !== null && field !== "" && field !== undefined ? true : false

  const [currentlyPlaying, setCurrentlyPlaying] = useState(null)
  const [paused, setPaused] = useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalImage, setModalImage] = useState(null)
  const player = useRef()

  const album = data.strapiAlbum
  const albumSlug = pageContext.albumSlug
  const notAlphaNum = /\W/g
  const space = /\s/g
  const slugArray = data.allStrapiAlbum.edges.map(({ node }) =>
    node.title.replace(space, "_").replace(notAlphaNum, "")
  )
  const currentIndex = slugArray.indexOf(albumSlug)
  const modifiedSlugArrayLength = slugArray.length - 1
  const prevPage = currentIndex - 1
  const nextPage = currentIndex + 1
  const notFirstEntry = currentIndex !== 0
  const notLastEntry = currentIndex !== modifiedSlugArrayLength
  const imageGallery = album.album_art.map(node => node.album_image.localFile)
  const ogImage = album.open_graph_image
  const isOgImage =
    ogImage !== null && ogImage !== "" && ogImage !== undefined
      ? ogImage.localFile.publicURL
      : false

  const handleClick = (e, track) => {
    e.preventDefault()
    if (track === currentlyPlaying && paused === false) {
      player.current.audio.current.pause()
      setPaused(true)
    } else if (track === currentlyPlaying && paused === true) {
      player.current.audio.current.play()
      setPaused(false)
    } else if (track !== currentlyPlaying) {
      setCurrentlyPlaying(track)
      setPaused(false)
    }
  }

  const modalClick = target => {
    setModalImage(target)
    if (modalIsOpen === false) {
      setModalIsOpen(true)
      document.getElementById("image-modal").showModal()
    } else {
      document.getElementById("image-modal").close()
      setModalIsOpen(false)
    }
  }

  return (
    <Layout>
      <SEO
        title={album.title}
        description={album.album_description}
        pageImage={isOgImage}
        pathname={albumSlug}
      />
      <div className="audio-album-container">
        <div className="audio-album-hero">
          <GatsbyImage image={getImage(album.album_hero.localFile)} alt="" />
        </div>
        <div className="audio-album-main-content">
          <div className="audio-album-title-line">
            <Link
              className="audio-album-nav"
              to={
                notFirstEntry
                  ? `/audio/${slugArray[prevPage]}`
                  : `/audio/${slugArray[modifiedSlugArrayLength]}`
              }
            >
              <div className="audio-album-icon">
                <Icon path={mdiChevronLeft} size={2} />
              </div>
            </Link>
            <div className="audio-album-title">
              <h1>{album.title}</h1>
              <h3>
                &#x000A0;&#x00028;
                <Moment local format="YYYY">
                  {album.release_date}
                </Moment>
                &#x00029;
              </h3>
            </div>
            <Link
              className="audio-album-nav"
              to={
                notLastEntry
                  ? `/audio/${slugArray[nextPage]}`
                  : `/audio/${slugArray[0]}`
              }
            >
              <div className="audio-album-icon">
                <Icon path={mdiChevronRight} size={2} />
              </div>
            </Link>
          </div>
          <div className="audio-album-main-content-body">
            <Monetizer />
            <div className="audio-album-main-content-columns">
              <div className="audio-album_column-1">
                <div className="audio-album-player">
                  <AudioPlayer
                    src={currentlyPlaying}
                    autoPlay={true}
                    ref={player}
                  />
                </div>
                <div className="audio-album-track-list">
                  {album.tracks.map((infos, index) => {
                    if (infos.song_credit === "original") {
                      const icon =
                        currentlyPlaying === infos.media.localFile.publicURL &&
                        paused === false
                          ? mdiPauseCircle
                          : mdiPlayCircle
                      return (
                        <div
                          key={index}
                          className={
                            currentlyPlaying === infos.media.localFile.publicURL
                              ? "audio-album-track-selected"
                              : "audio-album-track"
                          }
                          onClick={e =>
                            handleClick(e, infos.media.localFile.publicURL)
                          }
                        >
                          <div className="audio-album-num-tit">
                            <div>{infos.track_number}</div>
                            <div>{infos.track_title}</div>
                          </div>
                          <div className="audio-album-icons">
                            <Icon
                              path={icon}
                              size={1.75}
                              color={`var(--text-light)`}
                            />
                          </div>
                        </div>
                      )
                    } else {
                      return (
                        <div key={index} className="audio-album-track-disabled">
                          <div className="audio-album-num-tit">
                            <div>{infos.track_number}</div>
                            <div>{infos.track_title}</div>
                          </div>
                          <div className="audio-album-icons">
                            <Icon
                              path={mdiAlert}
                              size={1.75}
                              color={`var(--text-dark)`}
                            />
                          </div>
                        </div>
                      )
                    }
                  })}
                </div>
              </div>
              <div className="audio-album-column-2">
                {album.available_for_sale === true ? (
                  <div className="purchase-cd">
                    <span className="purchase-text">Purchase CD</span>
                    <div className="paypal-add-to-cart">
                      <form
                        target="paypal"
                        action="https://www.paypal.com/cgi-bin/webscr"
                        method="post"
                      >
                        <input type="hidden" name="cmd" value="_s-xclick" />
                        <input
                          type="hidden"
                          name="hosted_button_id"
                          value={`${album.paypal_item_id}`}
                        />
                        <input
                          type="image"
                          src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif"
                          border="0"
                          name="submit"
                          alt="PayPal - The safer, easier way to pay online!"
                        />
                        <img
                          alt=""
                          border="0"
                          src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif"
                          width="1"
                          height="1"
                        />
                      </form>
                    </div>
                  </div>
                ) : (
                  <div className="sold-out-cd">
                    <span className="sold-out-text">SOLD OUT!!</span>
                  </div>
                )}

                <div className="audio-album-cover">
                  <GatsbyImage
                    image={getImage(album.front_cover.localFile)}
                    alt=""
                  />
                </div>
                <ul className="media-scroller snaps-inline">
                  {album.album_art?.map((node, index) => {
                    const sliderImage = node.album_image?.sliderImage
                    const modalImage = node.album_image?.modalImage
                    return (
                      <li key={index} className="ms-element">
                        <GatsbyImage
                          className="audio-album-scroller-image"
                          image={getImage(sliderImage)}
                          alt=""
                          loading="lazy"
                          objectFit="contain"
                          onClick={() => modalClick(modalImage)}
                        />
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
          <div className="audio-album-notes-container">
            {nonNullField(album.design_notes) && (
              <div className="audio-album-notes">
                <h2 className="audio-album-notes-title">Design Notes</h2>
                <div className="audio-album-notes-body">
                  <ReactMarkdown children={album.design_notes} />
                </div>
              </div>
            )}
            {nonNullField(album.liner_notes) && (
              <div className="audio-album-notes">
                <h2 className="audio-album-notes-title">Liner Notes</h2>
                <div className="audio-album-notes-body">
                  <ReactMarkdown children={album.liner_notes} />
                </div>
              </div>
            )}
            {album.reviews.length > 0 && (
              <div className="audio-album-reviews audio-album-notes">
                <h2 className="audio-album-notes-title">Reviews and Press</h2>
                <ul className="audio-album-reviews-list audio-album-list">
                  {album.reviews.map((node, index) => {
                    if (nonNullField(node.web_link)) {
                      return (
                        <li
                          className="audio-album-reviews-list-item audio-album-card"
                          key={index}
                        >
                          <a
                            href={node.web_link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <div className="audio-album-reviews-header">
                              <Icon
                                className="audio-album-reviews-icon"
                                path={mdiOpenInNew}
                                size={1.25}
                              />
                              <h3 className="audio-album-reviews-list-title">
                                {node.title}
                              </h3>
                            </div>
                            {nonNullField(node.publication) && (
                              <h4 className="audio-album-reviews-publication">
                                {node.publication}
                              </h4>
                            )}
                            {nonNullField(node.date_of_review) && (
                              <p className="audio-album-review-date">
                                {node.date_of_review}
                              </p>
                            )}
                          </a>
                          {nonNullField(node.direct_quote) && (
                            <div className="audio-album-text">
                              <ReactMarkdown children={node.direct_quote} />
                            </div>
                          )}
                        </li>
                      )
                    } else {
                      return (
                        <li
                          className="audio-album-reviews-list-item audio-album-card"
                          key={index}
                        >
                          <h3 className="audio-album-reviews-list-title">
                            {node.title}
                          </h3>
                          {nonNullField(node.publication) && (
                            <h4 className="audio-album-reviews-publication">
                              {node.publication}
                            </h4>
                          )}
                          {nonNullField(node.date_of_review) && (
                            <p className="audio-album-review-date">
                              {node.date_of_review}
                            </p>
                          )}
                          {nonNullField(node.direct_quote) && (
                            <div className="audio-album-text">
                              <ReactMarkdown children={node.direct_quote} />
                            </div>
                          )}
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
      <dialog className="image-modal" id="image-modal">
        {modalIsOpen && (
          <div className="image-modal-wrapper">
            <div className="image-modal-close" onClick={() => modalClick(null)}>
              <Icon path={mdiClose} size={1} />
              <span>close</span>
            </div>
            <GatsbyImage
              image={getImage(modalImage)}
              alt=""
              objectFit="contain"
            />
          </div>
        )}
      </dialog>
    </Layout>
  )
}

export const query = graphql`
  query($albumTitle: String!) {
    strapiAlbum(title: { eq: $albumTitle }) {
      album_hero {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
          }
        }
      }
      front_cover {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 480, quality: 100)
          }
        }
      }
      open_graph_image {
        localFile {
          publicURL
        }
      }
      album_art {
        album_image {
          sliderImage: localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, height: 200, quality: 100)
            }
          }
          modalImage: localFile {
            childImageSharp {
              gatsbyImageData(quality: 100)
            }
          }
        }
      }
      album_description
      design_notes
      liner_notes
      title
      release_date
      paypal_item_id
      available_for_sale
      tracks {
        song_credit
        track_title
        track_number
        media {
          localFile {
            publicURL
          }
        }
      }
      reviews {
        direct_quote
        web_link
        publication
        title
        date_of_review
      }
    }
    allStrapiAlbum(
      filter: { album_type: { eq: "audio" } }
      sort: { fields: release_date, order: DESC }
    ) {
      edges {
        node {
          title
        }
      }
    }
  }
`
export default AudioAlbumAlt
